#expressionprofile {

  font-size: 10px;

  .axis path,
  .axis line {
    fill: none;
    stroke: grey;
    stroke-width: 1;
    shape-rendering: crispEdges;
  }

  #band {
    fill : none;
    stroke-width: 1px;
    stroke: red;
  }

  .selected {
    stroke-width: 5!important;
  }

  .pathover {
    stroke-width: 3;
  }

}