#phenodata-header-button {
  margin-left: 15px;
  color: gray;
}

#phenodata-header-button:hover {
  cursor: pointer;
  color: black;
}

.phenodata-button-group {
  margin-bottom: 15px;
}

.scrolling-wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
}
