#workflowvisualization {
  height: 100%;
  width: 100%;

  .selected-dataset {
    stroke-width: 5px;
  }

  .hovering-dataset {
    fill-opacity: 1;
  }

  .selected-job {
    stroke-width: 3px;
  }

  .hovering-job {
    fill-opacity: 1;
  }
}

.phenodata-node {
  cursor: default; // user-select doesn't work in all the browsers, so changing cursors explicitly
  user-select: none;
}

div.dataset-tooltip {
  position: absolute;
  text-align: center;
  padding: 2px 5px 2px 5px;
  font-size: 14px;
  font-weight: bold;
  color: white;
  background: #333333;
  border: 0;
  border-radius: 8px;
  pointer-events: none;
}

div.dataset-tooltip-triangle {
  position: absolute;
  text-align: left;
  padding: 0;
  box-sizing: border-box;
  display: inline;
  font-size: 14px;
  border: 0;
  line-height: 1;
  color: #333333;
  transform: scale(1.7, 1);
  pointer-events: none;
}

.node {
  stroke: black;
  stroke-width: 1px;
  // fill-opacity: 0.8;
}

.link {
  stroke: #555;
}

.scroller-div {
  overflow: auto;
  height: 100%;
  //flex-grow: 1;
  width: 100%;
  position: relative;
}

.toolbar-div {
  position: absolute;
  bottom: 10px;
}

.dataset-tooltip-div {
  position: absolute;
}

svg {
  // default is inline adding extra margin and showing scrollbar(s) unnecessarily
  display: block;
  position: absolute;
  padding-top: 15px;
}

div.dataset-node-tooltip {
  text-align: center;
  padding: 1px 2px 1px 2px;
  font-size: 12px;
  color: white;
  background: #333333;
  border: 0;
  border-radius: 5px;
  pointer-events: none;
  position: absolute;
  white-space: nowrap;
}
